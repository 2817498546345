html {
  box-sizing: border-box;
  font-size: 16px; }

*,
*:after,
*:before {
  box-sizing: border-box; }

body {
  font: 100% 'Roboto', arial, sans-serif;
  background: #f5f5f5; }

.hide-form {
  display: none; }

form {
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 30rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07); }

h1 {
  margin-top: 0;
  margin-bottom: 1.236rem;
  text-align: center;
  font-size: 1.618rem; }

h4 {
  margin-top: 0;
  padding: 0.25rem;
  margin-bottom: 1.236rem;
  text-align: center;
  font-size: 1.018rem; }

.form-group {
  padding: 0;
  border: 0; }
  .form-group + .form-group {
    margin-top: 1rem; }

label {
  display: inline-block;
  margin-bottom: .5rem;
  font-size: .75rem;
  text-transform: uppercase;
  touch-action: manipulation; }

input,
textarea {
  display: block;
  padding: .5rem .75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }
  input:focus,
  textarea:focus {
    outline: 0;
    border-bottom-color: #ffab00; }

textarea {
  resize: vertical; }

.btn {
  display: inline-block;
  padding: .75rem 1rem;
  margin-top: 1.618rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #ffab00;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  user-select: none;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }
  .btn:focus, .btn:hover {
    background-color: #ffc142;
    box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); }
  .btn:focus {
    outline: 0; }

.btn-sp {
  display: inline-block;
  padding: .75rem 1rem;
  margin-top: 1.618rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #ffab00;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  user-select: none;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }
  .btn-sp:focus, .btn-sp:hover {
    background-color: #ffc142;
    box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
    color: #000;
    text-decoration: none; }
  .btn-sp:focus {
    outline: 0; }

.required {
  border: 1px solid red; }
